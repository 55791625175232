import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";
import languageApi from "../api/language";
import PageTitle from "../components/common/PageTitle";
import EditLanguage from "../components/EditLanguage/EditLanguage.component";
import Featching from "../components/featching/featching";
import CreateLanguage from "../components/languages/CreateLanguage";
import { formatLanguages } from "../utils/formatResponse";

const Languages = () => {
	const columns = [
		{
			title: " Name",
			field: "language",
		},
		// {
		// 	title: "Status",
		// 	field: "status",
		// },
	];
	const [data, setData] = useState([]);
	const [openEditor, setOpenEditor] = useState(false);
	const [id, setId] = useState("");
	const [fetch, setFetch] = useState(false);
	const [name, setName] = useState("");

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		languageApi.getAll(fetchSuccess, onFaliure);
		// eslint-disable-next-line
	}, [fetch]);

	// const deleteSuccess = (res) => {
	// 	setFetch(!fetch);
	// 	toast.success(res.message);
	// };
	const onFaliure = (error) => {
		toast.error(error.message);
	};
	const fetchSuccess = (res) => {
		setLoading(false);

		setData(formatLanguages(res.data.languages));
	};

	// const activateDepartment = (id) => {
	// 	setLoading(true);
	// 	departmentsApi.activate(id, activationSuccess, onFaliure);
	// };
	// const deactivateDepartment = (id) => {
	// 	setLoading(true);
	// 	departmentsApi.deactivate(
	// 		id,
	// 		activationSuccess,

	// 		onFaliure
	// 	);
	// };
	// const activationSuccess = () => {
	// 	departmentsApi.getAll(fetchSuccess, onFaliure);
	// };

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Languages' className='text-sm-left' />
			</Row>

			<ToastContainer />
			<EditLanguage
				open={openEditor}
				toggle={() => setOpenEditor(!openEditor)}
				close={() => setOpenEditor(false)}
				id={id}
				name={name}
				setFetch={setFetch}
				fetch={fetch}
			/>
			<CreateLanguage setFetch={setFetch} fetch={fetch} />
			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader className='bg-dark'>
							<h6 className='m-0 text-white'>All Languages</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 table__body'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								actions={[
									{
										icon: "edit",
										tooltip: "Save User",
										onClick: (event, rowData) => {
											setOpenEditor(true);
											setId(rowData._id);
											setName(rowData.deptName);
										},
									},
									// (rowData) => ({
									// 	icon: rowData.status === "Active" ? "dangerous" : "check",
									// 	tooltip:
									// 		rowData.status === "Active" ? "Deactivate" : "Activate",
									// 	onClick: (event, rowData) => {
									// 		rowData.status === "Active"
									// 			? deactivateDepartment(rowData._id)
									// 			: activateDepartment(rowData._id);
									// 	},
									// 	disabled: rowData.active,
									// }),
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Languages;
