import { apiCall } from "./ApiCall";
const closedDaysApi = {
	deleteOne: (id, data, onSuccess, onFailure) =>
		apiCall(
			"post",
			data,
			`/doctor/${id}/delete-working-time`,
			onSuccess,
			onFailure
		),
	updateClosedDay: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/doctor/${id}/closed-days/`, onSuccess, onFailure),
};

export default closedDaysApi;
