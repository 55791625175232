import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import UserDetails from "../components/user-profile-lite/UserDetails";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import doctorsApi from "../api/doctors";
import userApi from "../api/user";
import { userSignedIn } from "../store/user-reducer";
import { removeKey } from "../utils/deleteKeys";
const EditDoctorProfile = () => {
	const user = useSelector((state) => state.user[0]);
	const [tempUser, setTempUser] = useState(user);
	const [fetch, setFetch] = useState(false);
	const dispatch = useDispatch();

	const [avatar, setAvatar] = useState(null);

	const handleSubmit = () => {
		toast.promise(
			doctorsApi.updateDoctor(
				sessionStorage.getItem("userId"),
				removeKey(tempUser),
				onSuccess,
				onFaileure
			),
			{
				pending: "proccessing ...",
			}
		);
	};

	useEffect(() => {
		doctorsApi.getOne(
			sessionStorage.getItem("userId"),
			onFetchSuccess,
			onFaileure
		);
		// eslint-disable-next-line
	}, [fetch]);

	const onSuccess = () => {
		console.log("profile update success");
		setFetch(!fetch);
	};
	const onFetchSuccess = (response) => {
		dispatch(userSignedIn(response.data.doctor));
		setTempUser(response.data.doctor);
	};
	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const changeAvatar = (e) => {
		const formData = new FormData();
		// HTML file input, chosen by user
		formData.append("image", e);
		userApi.updateAvatar(
			sessionStorage.getItem("userId"),
			formData,
			() => {
				setAvatar(URL.createObjectURL(e));
				setFetch(!fetch);
			},
			onFailure
		);
	};

	const onFailure = (error) => {
		console.log(error);
	};
	return (
		<Container fluid className='main-content-container px-4'>
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/doctors'>Doctors</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Docotr Profile'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>
			<ToastContainer />
			<Row>
				<Col lg='4'>
					{user && <UserDetails changeAvatar={changeAvatar} avatar={avatar} />}
				</Col>
				<Col lg='8'>
					{tempUser && (
						<UserAccountDetails
							user={tempUser}
							setUser={setTempUser}
							handleSubmit={handleSubmit}
						/>
					)}
				</Col>
			</Row>
		</Container>
	);
};
export default EditDoctorProfile;
