import { apiCall, apiFetch } from "./ApiCall";
const departmentsApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/department", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", `/department/all`, onSuccess, onFailure),
	getActive: (onSuccess, onFailure) =>
		apiFetch("get", `/department/available-departments`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/department/${id}`, onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/department/${id}`, onSuccess, onFailure),
	activate: (id, onSuccess, onFailure) =>
		apiCall("put", {}, `/department/${id}/activate`, onSuccess, onFailure),
	deactivate: (id, onSuccess, onFailure) =>
		apiCall("put", {}, `/department/${id}/deactivate`, onSuccess, onFailure),
};

export default departmentsApi;

// updateOne: (data, id, onSuccess, onFailure) =>
// 		apiCall("put", data, `/supplier/${id}`, onSuccess, onFailure),
