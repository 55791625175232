import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Modal,
	Row,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { formatAMPM } from "../utils/formatAMPM";

import closedDaysApi from "../api/closedDays";
import TimeSelector from "../components/TimeSelector/TimeSelector.component";
import { formatDate } from "../utils/formatDate";

const ClosedDays = () => {
	const [filter, setFilter] = useState(0);
	const [open, setOpen] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [fetch, setFetch] = useState(false);
	const [date, setDate] = useState(new Date());

	const doctor = useSelector((state) => state.user[0]);

	useEffect(() => {
		doctor && doctor.closedDays
			? setShifts(
					doctor.closedDays.filter((days) => new Date() <= new Date(days.day))
			  )
			: setShifts([]);
	}, [doctor, fetch, filter]);

	const toggle = () => {
		setOpen(!open);
	};
	const handleDelete = (id) => {
		closedDaysApi.deleteOne(
			doctor._id,
			{
				workingTimeId: id,
				type: "closed",
			},
			deleteSuccess,
			deleteFaileure
		);
	};

	const deleteFaileure = (error) => {
		console.log("This Failed : ", error);

		setFetch(!fetch);
	};
	const deleteSuccess = (resp) => {
		setFetch(!fetch);
		setFilter(0);
	};
	const handleSave = (newSlot) => {
		toast.promise(
			closedDaysApi.updateClosedDay(
				{
					day: date,
					closedHours: [{ ...newSlot }],
				},
				doctor._id,
				onSuccess,
				onFaileure
			),
			{
				pending: "Saving ...",
			}
		);
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);

		setFetch(!fetch);
	};

	const onSuccess = () => {
		setFetch(!fetch);
	};

	const changeTab = (e) => {
		setFilter(e.target.value);
	};
	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title='Edit Closed Day'
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>
			<TimeSelector
				open={open}
				toggle={toggle}
				handleChange={handleSave}
				date={date}
				setDate={setDate}
				fetch={fetch}
				setFetch={setFetch}
			/>

			<Row>
				{/* Editor */}
				<Col lg='12' md='12'>
					<Card small className='mb-3 mt-4 '>
						<Row
							style={{
								padding: "30px 50px",
								gridGap: "20px",
								height: "85px",
								marginBottom: "20px",
								paddingLeft: "35px",
							}}
							className='desktop'
						>
							<Col md='4' sm='4' className='form-group mt-3'>
								<Button theme='success' className='save ' onClick={toggle}>
									Add New Date
								</Button>
							</Col>
						</Row>

						<Col md='4' sm='4' className='form-group  mobile'>
							<Button theme='success' onClick={toggle}>
								Add New Date
							</Button>
						</Col>
						{shifts[0] && <hr />}
						{shifts[0] && (
							<Row
								style={{
									padding: "30px 50px",
									display: "grid",
									gridTemplateColumns: `repeat(${shifts.length},1fr)`,
									gridGap: "20px",
									height: "85px",
									paddingBottom: "0",
								}}
								className='desktop'
							>
								{shifts[0] &&
									shifts.map((day, index) => (
										<Button
											theme='primary'
											className='mb-2 mr-1  w100 hover-red'
											outline={index === parseInt(filter) ? false : true}
											value={index}
											onClick={changeTab}
											name={day}
											key={index}
										>
											{formatDate(new Date(day.day))}
										</Button>
									))}
							</Row>
						)}
						<Row
							className='mobile'
							style={{ padding: "0 30px", paddingTop: "30px" }}
						>
							<Row lg='8'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect id='Days' onChange={changeTab}>
										{shifts[0] ? (
											shifts.map((day, index) => (
												<option
													value={index}
													onClick={changeTab}
													name={day}
													key={index}
												>
													{formatDate(new Date(day.day))}
												</option>
											))
										) : (
											<option>No Closed Date</option>
										)}
									</FormSelect>
								</Col>
							</Row>
						</Row>
						<hr />
						<Row
							style={{
								padding: "10px 30px",
							}}
						>
							<Modal show />
							{shifts[0] ? (
								<Col>
									<h4>Time Slots</h4>
								</Col>
							) : (
								<Col>
									<h4>No Closed Days</h4>
								</Col>
							)}
						</Row>
						<Row className='time-slot-container'>
							{shifts[0] &&
								shifts[filter] &&
								shifts[filter].closedHours.map((schedule, index) => (
									<Button
										theme='primary'
										key={index}
										value={schedule._id}
										className='mb-2 mr-1 time-slots'
										onClick={() => handleDelete(shifts[filter]._id)}
									>
										{`${formatAMPM(
											schedule.startHour.hour,
											schedule.startHour.minute
										)}
										-
										${formatAMPM(schedule.endHour.hour, schedule.endHour.minute)}`}
										<span value={schedule._id} className='close'>
											X
										</span>
									</Button>
								))}
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ClosedDays;
