import { apiCall, apiFetch } from "./ApiCall";
const loactionApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/address/", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/address/", onSuccess, onFailure),
	getOne: (id, onSuccess, onFailure) =>
		apiFetch("get", `/address/${id}`, onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/address/${id}`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/address/${id}`, onSuccess, onFailure),
};

export default loactionApi;
