const func = () => {
	return [
		{
			title: "Dashboard",
			to: "/admin/overview",
			htmlBefore: '<i class="material-icons">edit</i>',
			htmlAfter: "",
		},
		{
			title: "Departments",
			htmlBefore: '<i class="material-icons">person</i>',
			to: "/admin/departments",
		},
		{
			title: "Doctors",
			htmlBefore: '<i class="material-icons">person</i>',
			to: "/admin/doctors",
		},
		{
			title: "Patients",
			htmlBefore: '<i class="material-icons">person</i>',
			to: "/admin/patients",
		},
		{
			title: "Appointments",
			htmlBefore: '<i class="material-icons">book</i>',
			to: "/admin/appointments",
		},
		{
			title: "Edit Regular",
			htmlBefore: '<i class="material-icons">access_time_filled</i>',
			to: "/admin/edit-regular",
		},
		{
			title: "Edit Part-time ",
			htmlBefore: '<i class="material-icons">access_time</i>',
			to: "/admin/edit-part-time",
		},
		{
			title: "Edit Special Days",
			htmlBefore: '<i class="material-icons">star</i>',
			to: "/admin/edit-special-days",
		},

		{
			title: "Closed Days",
			htmlBefore: '<i class="material-icons"  style="color:red">close</i>',
			to: "/admin/closed-days",
		},
		// {
		// 	title: "Stock Activity Log ",
		// 	htmlBefore: '<i class="material-icons" >inventory</i>',
		// 	to: "/admin/stock-log",
		// },
		// {
		// 	title: "Tables",
		// 	htmlBefore: '<i class="material-icons">table_chart</i>',
		// 	to: "/tables",
		// },
		// {
		// 	title: "User Profile",
		// 	htmlBefore: '<i class="material-icons">person</i>',
		// 	to: "/user-profile-lite",
		// },
		// {
		// 	title: "Errors",
		// 	htmlBefore: '<i class="material-icons">error</i>',
		// 	to: "/components-overview",
		// },
		{
			title: "Adminstrators",
			htmlBefore: '<i class="material-icons">admin_panel_settings</i>',
			to: "/admin/admins",
		},
		{
			title: "Working Time Range",
			htmlBefore: '<i class="material-icons">more_time</i>',
			to: "/admin/edit-time-range",
		},
		{
			title: "Languages",
			htmlBefore: '<i class="material-icons">flag</i>',
			to: "/admin/languages",
		},
		{
			title: "Address",
			htmlBefore: '<i class="material-icons">pin</i>',
			to: "/admin/address",
		},
	];
};

export default func;
