import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
	Card,
	FormInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "shards-react";
import login from "../assets/images/login.png";
import logo from "../assets/images/logo.png";
import { userSignedIn } from "../store/user-reducer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import userApi from "../api/user";

const LogIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = (e) => {
		e.preventDefault();
		sessionStorage.clear();
		sessionStorage.clear();
		toast.promise(
			userApi.login(
				{ password: password, email: email },
				onSuccess,
				onFaileure
			),
			{
				success: "",
				pending: "Authenticating....",
			}
		);
	};
	const onSuccess = (data) => {
		userApi.getRefreshToken(
			{
				refresh_token: data.data.refresh_token,
			},
			refreshTokenSuccess,
			onFaileure
		);
	};
	const refreshTokenSuccess = (res) => {
		sessionStorage.setItem("token", res.data.access_token);
		setTimeout(() => {
			sessionStorage.setItem("userId", res.data.user._id);
			sessionStorage.setItem("role", res.data.user.role);
			if (res.data.user.role === "admin") {
				dispatch(userSignedIn(res.data.user));

				navigate("/admin/overview");
			} else {
				dispatch(userSignedIn(res.data.user));
				navigate("/doc/overview");
			}
		}, 500);
	};
	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};
	return (
		<div>
			<ToastContainer />
			<div className='top-nav'>
				<img className='logo' src={logo} alt='logo' />
			</div>
			<div className='two-by-two'>
				<img src={login} className='login-vector' alt='home vector' />
				<form className='form' onSubmit={handleLogin} method='post'>
					<Card className='form-card'>
						<Row>
							<h2 style={{ marginBottom: "30px" }}>Login</h2>
						</Row>
						<Row style={{ marginBottom: "20px" }}>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>mail</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={email}
									name='email'
									required
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									placeholder='Email'
								/>
							</InputGroup>
						</Row>
						<Row style={{ marginBottom: "20px" }}>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>password</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={password}
									name='password'
									required
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									type='password'
									placeholder='Password'
								/>
							</InputGroup>
						</Row>
						<Row
							style={{
								display: "flex!important",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<span></span>
							<Link to='/user/forgot-password' style={{ float: "right" }}>
								Forgot Password?
							</Link>
						</Row>
						<Row style={{ margin: "10px auto" }}>
							<button className='login-button' type='submit'>
								Login
							</button>
						</Row>
					</Card>
				</form>
			</div>
		</div>
	);
};

export default LogIn;
