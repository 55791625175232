import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, ListGroup, ListGroupItem } from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";

const UserDetails = ({ changeAvatar, avatar }) => {
	const user = useSelector((state) => state.user[0]);

	return (
		<Card small className='mb-4 pt-3'>
			<CardHeader className='border-bottom text-center'>
				<div className='mb-3 mx-auto'>
					<img
						className='rounded-circle'
						src={avatar ? avatar : user.profilePicture}
						alt={user.name}
						width='110'
					/>
				</div>
				<h4 className='mb-0'>{user.name}</h4>
				<span className='text-muted d-block mb-2'>{user.jobTitle}</span>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-4'>
					<strong className='text-muted d-block mb-2'>
						Specialization: {user.specialization}
					</strong>
					<CustomFileUpload onChange={changeAvatar} />
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

UserDetails.propTypes = {
	/**
	 * The user details object.
	 */
	userDetails: PropTypes.object,
};

UserDetails.defaultProps = {
	userDetails: {
		name: "Dr. Jone Doe",
		avatar: "3.jpg",
		jobTitle: "Cardiologist",
		performanceReportTitle: "Workload",
		performanceReportValue: 74,
		metaTitle: "Description",
		metaValue:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?",
	},
};

export default UserDetails;
