import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";
import patientApi from "../api/patients";
import PageTitle from "../components/common/PageTitle";
import Confirmation from "../components/confirm/confirm.component";
import Featching from "../components/featching/featching";
import { formatPatient } from "../utils/formatResponse";

const Patients = () => {
	const columns = [
		{ title: "Name", field: "name" },

		{
			title: "Email",
			field: "email",
		},
		{
			title: "Phone Number",
			field: "phone",
		},
	];

	const [loading, setLoading] = useState(true);

	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [id, setId] = useState("");
	const [fetch, setFetch] = useState(false);

	useEffect(() => {
		setLoading(true);
		patientApi.getAll(onSuccess, onFaliure);
	}, [fetch]);

	const onSuccess = (res) => {
		setLoading(false);
		console.log("false", res);
		setData(formatPatient(res.data.patients));
	};

	const onFaliure = (error) => {
		console.log("This Failed : ", error);
	};

	const handleDelete = (id) => {
		console.log("this is id", id);
		patientApi.deleteOne(
			id,
			(res) => {
				setFetch(!fetch);
				toast.success(res.message);
			},
			(error) => {
				setId("");
				toast.success(error.data.message);
			}
		);
	};
	const callBack = (id) => {
		handleDelete(id);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Patients' className='text-sm-left' />
			</Row>
			<ToastContainer />
			<Confirmation
				open={open}
				callback={callBack}
				close={() => setOpen(false)}
				id={id}
			/>
			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader className='bg-dark'>
							<h6 className='m-0 text-white'>All Patients</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 table__body'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Patients;
