import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			navItems:
				sessionStorage.getItem("role") === "admin"
					? Store.getAdminNavItems()
					: Store.getDoctorNavItems(),
		};

		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		Store.addChangeListener(this.onChange);
	}

	componentWillUnmount() {
		Store.removeChangeListener(this.onChange);
	}

	onChange() {
		this.setState({
			...this.state,
			navItems:
				sessionStorage.getItem("role") === "admin"
					? Store.getAdminNavItems()
					: Store.getDoctorNavItems(),
		});
	}

	render() {
		const { navItems: items } = this.state;

		return (
			<div className='nav-wrapper'>
				<Nav className='nav--no-borders flex-column'>
					{items.map((item, idx) => (
						<SidebarNavItem key={idx} item={item} />
					))}
				</Nav>
			</div>
		);
	}
}

export default SidebarNavItems;
