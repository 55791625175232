import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";

const StockLog = () => (
	<Container fluid className='main-content-container px-4'>
		{/* Page Header */}
		<Row noGutters className='page-header py-4'>
			<PageTitle
				sm='4'
				title='Stock Activity Log'
				subtitle='Stock'
				className='text-sm-left'
			/>
		</Row>

		<Row>
			<Col>
				<Card small className='mb-4 overflow-hidden'>
					<CardHeader className='bg-dark'>
						<h6 className='m-0 text-white'>Active Users</h6>
					</CardHeader>
					<CardBody className='bg-light p-0 pb-3'>
						<table className='table table-light mb-0'>
							<thead className='thead-light'>
								<tr>
									<th scope='col' className='border-0'>
										Time
									</th>
									<th scope='col' className='border-0'>
										Docotr
									</th>
									<th scope='col' className='border-0'>
										Patient
									</th>
									<th scope='col' className='border-0'>
										Item
									</th>
									<th scope='col' className='border-0'>
										Quantity
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										{new Date().getFullYear()}/{new Date().getMonth()}/
										{new Date().getDate()} - {new Date().getHours()}:
										{new Date().getMinutes()}
									</td>
									<td>Dr. Jane Doe</td>
									<td>Patient 1</td>
									<td>Syring 5cc</td>
									<td>5</td>
								</tr>
								<tr>
									<td style={{ borderBottom: "1px solid black!important" }}>
										{new Date().getFullYear()}/{new Date().getMonth()}/
										{new Date().getDate()} - {new Date().getHours()}:
										{new Date().getMinutes()}
									</td>
									<td>Dr. Jane Doe</td>
									<td>Patient 1</td>
									<td>Syring 5cc</td>
									<td>5</td>
								</tr>
								<tr>
									<td>
										{new Date().getFullYear()}/{new Date().getMonth()}/
										{new Date().getDate()} - {new Date().getHours()}:
										{new Date().getMinutes()}
									</td>
									<td>Dr. Jane Doe</td>
									<td>Patient 1</td>
									<td>Syring 5cc</td>
									<td>5</td>
								</tr>
								<tr>
									<td>
										{new Date().getFullYear()}/{new Date().getMonth()}/
										{new Date().getDate()} - {new Date().getHours()}:
										{new Date().getMinutes()}
									</td>
									<td>Dr. Jane Doe</td>
									<td>Patient 1</td>
									<td>Syring 5cc</td>
									<td>5</td>
								</tr>
							</tbody>
						</table>
					</CardBody>
				</Card>
			</Col>
		</Row>
	</Container>
);

export default StockLog;
