import "./loading.css";
const Loading = () => {
	return (
		<div>
			<h4 className='text'>
				Activating your account this may take a moment ...
			</h4>
			<div className='loader'>
				<div className='item-1'>
					<span></span>
				</div>
				<div className='item-2'>
					<span></span>
				</div>
				<div className='item-3'>
					<span></span>
				</div>
				<div className='item-4'>
					<span></span>
				</div>
				<div className='item-5'>
					<span></span>
				</div>
				<div className='item-6'>
					<span></span>
				</div>
				<div className='item-7'>
					<span></span>
				</div>
				<div className='item-8'>
					<span></span>
				</div>
			</div>
		</div>
	);
};

export default Loading;
