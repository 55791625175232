import { apiCall, apiFetch } from "./ApiCall";
const scheduleApi = {
	getWorkingTime: (onSuccess, onFailure) =>
		apiFetch("get", "/working-days/", onSuccess, onFailure),
	removeDay: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/working-days/${id}`, onSuccess, onFailure),

	addFullTimeWorkingTime: (data, onSuccess, onFailure) =>
		apiCall("post", data, `/working-days/`, onSuccess, onFailure),
	updatePartTime: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/doctor/${id}/weekly-days/`, onSuccess, onFailure),
	updateFullTime: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/working-days/${id}`, onSuccess, onFailure),
	deleteWorkingTime: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/working-days/${id}`, onSuccess, onFailure),
};

export default scheduleApi;

// add: (data, onSuccess, onFailure) =>
// apiCall("post", data, "/procedure/", onSuccess, onFailure),
// getAll: (onSuccess, onFailure) =>
// apiFetch("get", "/procedure/", onSuccess, onFailure),
// deleteOne: (id, onSuccess, onFailure) =>
// apiFetch("delete", `/procedure/${id}`, onSuccess, onFailure),
// updateOne: (data, id, onSuccess, onFailure) =>
// apiCall("put", data, `/procedure/${id}`, onSuccess, onFailure),
