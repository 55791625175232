import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Modal,
	Row,
} from "shards-react";
import scheduleApi from "../api/schedules";
import PageTitle from "../components/common/PageTitle";
import TimeSelector from "../components/TimeSelector/TimeSelector.component";
import { removeKey } from "../utils/deleteKeys";
import { formatAMPM } from "../utils/formatAMPM";

const EditRegularDoctorSchedule = () => {
	let weekDays = [
		"Sunday",
		"Monday",
		"Tusday",
		"Wednsday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	var d = new Date();
	var dayName = weekDays[d.getDay()];
	const [filter, setFilter] = useState({ day: dayName, index: d.getDay() });
	const [open, setOpen] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [fetch, setFetch] = useState(false);
	useEffect(() => {
		scheduleApi.getWorkingTime(
			(res) => {
				setShifts(
					res.data.workingDays.filter(
						(schedule) => schedule.day === filter.index
					)
				);
			},
			(error) => {
				console.log("This Failed : ", error);
			}
		);
	}, [filter, fetch]);

	const toggle = () => {
		setOpen(!open);
	};
	const handleChange = (newSlot) => {
		let temp = { ...shifts[0] };

		function isObjectEmpty(obj) {
			return Object.keys(obj).length === 0;
		}

		if (isObjectEmpty(temp)) {
			temp = {
				day: filter.index,
				suspense: false,
				shifts: [newSlot],
			};
			setShifts([temp]);
		} else {
			temp.shifts.push(newSlot);
		}
	};

	const handleDelete = (e) => {
		let index = shifts[0].shifts.findIndex((sh) => sh._id === e.target.value);
		let temp = { ...shifts[0] };
		console.log(temp.shifts.splice(index, 1));
		setShifts([temp]);
	};

	const handleSave = () => {
		if (shifts[0].shifts.length === 0) {
			toast.promise(
				scheduleApi.removeDay(shifts[0]._id, onSuccess, onFaileure),
				{
					pending: "Saving ...",
				}
			);
		} else if (shifts[0] && shifts[0]._id) {
			toast.promise(
				scheduleApi.updateFullTime(
					removeKey(shifts[0]),
					shifts[0]._id,
					onSuccess,
					onFaileure
				),
				{
					pending: "Saving ...",
				}
			);
		} else {
			toast.promise(
				scheduleApi.addFullTimeWorkingTime(shifts[0], onSuccess, onFaileure),
				{
					pending: "Saving ...",
				}
			);
		}
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (data) => {
		setFetch(!fetch);

		console.log("This is Success : ", data);
	};

	const changeTab = (e) => {
		setFilter({ day: e.target.name, index: parseInt(e.target.value) });
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title="Edit Regular Doctor's Schedule"
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>
			<TimeSelector
				open={open}
				toggle={toggle}
				day={filter.day.toLocaleLowerCase()}
				handleChange={handleChange}
			/>
			<Row>
				{/* Editor */}
				<Col lg='12' md='12'>
					<Card small className='mb-3 mt-4 '>
						<Row
							style={{
								padding: "30px 50px",
								display: "grid",
								gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
								gridGap: "20px",
								height: "85px",
								paddingBottom: "0",
							}}
							className='desktop'
						>
							{weekDays &&
								weekDays.map((day, index) => (
									<Button
										theme='primary'
										className='mb-2 mr-1  w100 hover-red'
										outline={filter.day !== day}
										value={index}
										onClick={changeTab}
										name={day}
										key={index}
									>
										{day}
									</Button>
								))}
						</Row>
						<Row
							style={{
								padding: "30px 30px",

								gridGap: "20px",
								height: "85px",
							}}
							className='mobile'
						>
							<Row lg='12'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect id='Days' onChange={changeTab}>
										{weekDays &&
											weekDays.map((day, index) => (
												<option
													value={index}
													onClick={changeTab}
													name={day}
													key={index}
												>
													{day}
												</option>
											))}
									</FormSelect>
								</Col>
							</Row>
						</Row>
						<hr />
						<Row
							style={{
								padding: "10px 30px",
							}}
						>
							<Modal show />
							<Col>
								<h4>Time Slots</h4>
							</Col>
							<Col>
								<Button
									theme='success'
									style={{ float: "right", width: "auto" }}
									onClick={toggle}
								>
									Add New Slot
								</Button>
							</Col>
						</Row>
						<hr />

						<Row className='time-slot-container'>
							{shifts[0] &&
								shifts[0].shifts.map((schedule, index) => (
									<Button
										theme='primary'
										key={index}
										value={schedule._id}
										className='mb-2 mr-1 time-slots'
									>
										{`${formatAMPM(
											schedule.startHour.hour,
											schedule.startHour.minute
										)}
										-
										${formatAMPM(schedule.endHour.hour, schedule.endHour.minute)}`}
										<button
											value={schedule._id}
											className='close'
											onClick={handleDelete}
										>
											X
										</button>
									</Button>
								))}
						</Row>
						<hr />
						<Button theme='success' className='save' onClick={handleSave}>
							Save Changes
						</Button>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EditRegularDoctorSchedule;
