import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Modal,
	Row,
} from "shards-react";
import scheduleApi from "../api/schedules";
import { formatAMPM } from "../utils/formatAMPM";

const RegularDoctorSchedule = () => {
	let weekDays = [
		"Sunday",
		"Monday",
		"Tusday",
		"Wednsday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	var d = new Date();
	var dayName = weekDays[d.getDay()];
	const [filter, setFilter] = useState({ day: dayName, index: d.getDay() });
	const [shifts, setShifts] = useState([]);

	useEffect(() => {
		scheduleApi.getWorkingTime(
			(res) => {
				setShifts(
					res.data.workingDays.filter(
						(schedule) => schedule.day === filter.index
					)
				);
			},
			(error) => {
				console.log("This Failed : ", error);
			}
		);
	}, [filter]);

	const changeTab = (e) => {
		setFilter({ day: e.target.name, index: parseInt(e.target.value) });
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />

			<Row>
				{/* Editor */}
				<Col lg='12' md='12'>
					<Card small className='mb-3 mt-4 '>
						<Row
							style={{
								padding: "30px 50px",
								display: "grid",
								gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
								gridGap: "20px",
								height: "85px",
								paddingBottom: "0",
							}}
							className='desktop'
						>
							{weekDays &&
								weekDays.map((day, index) => (
									<Button
										theme='primary'
										className='mb-2 mr-1  w100 hover-red'
										outline={filter.day !== day}
										value={index}
										onClick={changeTab}
										name={day}
										key={index}
									>
										{day}
									</Button>
								))}
						</Row>
						<Row
							style={{
								padding: "30px 30px",

								gridGap: "20px",
								height: "85px",
							}}
							className='mobile'
						>
							<Row lg='12'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect id='Days' onChange={changeTab}>
										{weekDays &&
											weekDays.map((day, index) => (
												<option
													value={index}
													onClick={changeTab}
													name={day}
													key={index}
												>
													{day}
												</option>
											))}
									</FormSelect>
								</Col>
							</Row>
						</Row>
						<hr />
						<Row
							style={{
								padding: "10px 30px",
							}}
						>
							<Modal show />
							<Col>
								<h4>Time Slots</h4>
							</Col>
							<Col></Col>
						</Row>
						<Row className='time-slot-container'>
							{shifts[0] &&
								shifts[0].shifts.map((schedule, index) => (
									<Button
										theme='primary'
										key={index}
										value={schedule._id}
										className='mb-2 mr-1 time-slots'
									>
										{`${formatAMPM(
											schedule.startHour.hour,
											schedule.startHour.minute
										)}
										-
										${formatAMPM(schedule.endHour.hour, schedule.endHour.minute)}`}
									</Button>
								))}
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default RegularDoctorSchedule;
