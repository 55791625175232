import "./featching.css";
const Featching = () => {
	return (
		<div>
			<div className='featcher'>
				<div className='item-1'>
					<span></span>
				</div>
				<div className='item-2'>
					<span></span>
				</div>
				<div className='item-3'>
					<span></span>
				</div>
				<div className='item-4'>
					<span></span>
				</div>
				<div className='item-5'>
					<span></span>
				</div>
				<div className='item-6'>
					<span></span>
				</div>
				<div className='item-7'>
					<span></span>
				</div>
				<div className='item-8'>
					<span></span>
				</div>
			</div>
		</div>
	);
};

export default Featching;
