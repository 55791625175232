import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Card,
	Form,
	FormFeedback,
	FormInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "shards-react";
import logo from "../assets/images/logo.png";
import login from "../assets/images/password.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import userApi from "../api/user";

const ChangePassword = () => {
	const [oldPassword, setOldPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPasword, setConfirmPassword] = useState("");
	const [match, setMatch] = useState("");

	const navigate = useNavigate();

	useEffect(() => {
		if (password === confirmPasword && password.length > 1) {
			setMatch(false);
		} else if (
			password !== confirmPasword &&
			password.length > 1 &&
			confirmPasword.length > 1
		) {
			setMatch(true);
		}
	}, [password, confirmPasword]);

	const handleReset = (e) => {
		e.preventDefault();
		if (password === confirmPasword) {
			toast.promise(
				userApi.changePassword(
					{
						currentPassword: oldPassword,
						newPassword: password,
					},
					onSuccess,
					onFaileure
				),
				{
					pending: "saving...",
				}
			);
		} else {
			setMatch(true);
		}
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (data) => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<div>
			<ToastContainer />
			<div className='top-nav'>
				<img className='logo' src={logo} alt='logo' />
			</div>
			<div className='two-by-two'>
				<img src={login} className='login-vector' alt='home vector' />
				<Form className='form' onSubmit={handleReset} method='post'>
					<Card className='form-card'>
						<Row>
							<h2 style={{ marginBottom: "30px" }}>Change Your password </h2>
						</Row>

						<Row>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>lock</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={oldPassword}
									onChange={(e) => {
										setOldPassword(e.target.value);
									}}
									placeholder='Current Password'
									type='password'
									required
								/>
							</InputGroup>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>lock</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									type='password'
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder='New Password'
									invalid={match}
									valid={match === false}
									required
								/>
							</InputGroup>

							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>lock</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									type='password'
									visible
									value={confirmPasword}
									onChange={(e) => {
										setConfirmPassword(e.target.value);
									}}
									placeholder='Confirm New Password'
									invalid={match}
									valid={match === false}
									required
								/>
							</InputGroup>
							<FormFeedback>Passwords didn't match </FormFeedback>
						</Row>

						<Row style={{ margin: "10px auto" }}>
							<button className='login-button' type='submit'>
								Save Password
							</button>
						</Row>
					</Card>
				</Form>
			</div>
		</div>
	);
};

export default ChangePassword;
