import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "shards-react";
import DoctorDetailCard from "../components/Doctors/ViewDoctors";
import doctorsApi from "../api/doctors";
import { useDispatch } from "react-redux";
import { doctorsAdded } from "../store/doctors-reducer";
const DoctorDetail = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const doctor = useSelector((state) =>
		state.doctors.find((doc) => doc._id === params.id)
	);
	const [data, setData] = useState(doctor);
	useEffect(() => {
		doctorsApi.getAll(onSuccess, onFaileure);
		// eslint-disable-next-line
	}, []);

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (res) => {
		dispatch(doctorsAdded(res.data.filter((user) => user.role !== "admin")));
		setData(res.data.find((doc) => doc._id === params.id));
	};
	return (
		<Container fluid className='main-content-container px-4'>
			<ToastContainer style={{ paddingTop: "50px" }} />

			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/doctors'>Doctors</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Doctor Detail'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>
			<Row>
				{/* <Col lg='4'>
						<UserProfile userDetails={this.props.user} setAvatar={setAvatar} />
					</Col> */}
				<Col lg='12'>
					<DoctorDetailCard data={data} id={params.id} />
				</Col>
			</Row>
		</Container>
	);
};

export default DoctorDetail;
