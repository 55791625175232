import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "languages",
	initialState: [],
	reducers: {
		languagesAdded: (language, action) => {
			return [...action.payload];
		},
	},
});

export const { languagesAdded } = slice.actions;

export default slice.reducer;
