import axios from "axios";
import { ENDPOINT } from "./Api";

import { toast } from "react-toastify";

export const apiCall = (method, data, path, callback, onFailure, token) =>
	new Promise((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `${token ? token : sessionStorage.getItem("token")}`,
			},
		};
		console.log(
			"This is request",
			"Method : ",
			method,
			"Data : ",
			data,
			"Path",
			path
		);
		axios[method](`${ENDPOINT}${path}`, data && data, config)
			.then((res) => {
				resolve();
				callback(res);
				console.log("This is Success : ", res);
				toast.success(res.data.message);
			})
			.catch((error) => {
				reject();
				console.log("This Failed : ", error);

				onFailure(error);
				toast.error(error.response.data.message);
			});
	});

export const apiFetch = (method, path, callback, onFailure) =>
	new Promise((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `${sessionStorage.getItem("token")}`,
			},
		};
		console.log("This is request", "Method : ", method, "Path", config);
		axios[method](`${ENDPOINT}${path}`, config)
			.then((res) => {
				resolve();
				callback(res);
				if (method === "delete") {
					toast.success(res.data.message);
				}
			})
			.catch((error) => {
				reject();
				onFailure(error);
			});
	});
