import PropTypes from "prop-types";
import React from "react";
import { NavLink as RouteNavLink } from "react-router-dom";
import { Container, Nav, NavItem, NavLink, Row } from "shards-react";

const MainFooter = ({ contained, menuItems, copyright }) => (
	<footer className='main-footer d-flex p-2 px-3 bg-white border-top'>
		<Container fluid={contained}>
			<Row>
				<Nav>
					{menuItems.map((item, idx) => (
						<NavItem key={idx}>
							<NavLink
								tag={(props) => <RouteNavLink {...props} />}
								to={item.to}
							>
								{item.title}
							</NavLink>
						</NavItem>
					))}
				</Nav>
				<span className='copyright ml-auto my-auto mr-2'>{copyright}</span>
			</Row>
		</Container>
	</footer>
);

MainFooter.propTypes = {
	/**
	 * Whether the content is contained, or not.
	 */
	contained: PropTypes.bool,
	/**
	 * The menu items array.
	 */
	menuItems: PropTypes.array,
	/**
	 * The copyright info.
	 */
	copyright: PropTypes.string,
};

MainFooter.defaultProps = {
	contained: false,
	copyright: (
		<a href='https://adey-meselesh.de/en' target='_blank'>
			{" "}
			Copyright © 2022 Adey Meselesh GmbH
		</a>
	),
	menuItems: [],
};

export default MainFooter;
