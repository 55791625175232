import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import userApi from "../api/user";
import PageTitle from "../components/common/PageTitle";
import UserAccountForm from "../components/user-profile-lite/UserAccountForm";

class CreateAdminProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			userName: "",
			phoneNumber: "",
			employmentType: "part-time",
			email: "",
			gender: "male",
			specialization: "",
			birthDate: "",
			bio: "",
			password: "",
			department: "",
			role: "admin",
			address: "",
			languages: [],
		};
	}

	render() {
		let handleSubmit = () => {
			toast.promise(
				userApi.register(
					{ ...this.state },

					onSuccess,
					onFaileure
				),
				{
					pending: "proccessing ...",
				}
			);
		};

		const onSuccess = (resp) => {
			console.log("this is success", resp);
		};
		const onFaileure = (error) => {
			console.log("This Failed : ", error);
		};

		const handleChange = (event) => {
			this.setState({ [event.target.name]: event.target.value });
		};

		return (
			<Container fluid className='main-content-container px-4'>
				<ToastContainer style={{ paddingTop: "50px" }} />

				<Row noGutters className='page-header py-4'>
					<Breadcrumb>
						<BreadcrumbItem>
							<Link to='/admin/admins'>Admins</Link>
						</BreadcrumbItem>
						<BreadcrumbItem active>
							<PageTitle
								title=''
								subtitle='Create Profile'
								md='12'
								className='ml-sm-auto mr-sm-auto'
							/>
						</BreadcrumbItem>
					</Breadcrumb>
				</Row>
				<Row>
					<Col lg='12'>
						<UserAccountForm
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							state={this.state}
							setPhone={(phone) => this.setState({ phoneNumber: phone })}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

export default connect(mapStateToProps)(CreateAdminProfile);
