import React from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import UserAccountForm from "../components/user-profile-lite/UserAccountForm";

import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "shards-react";
import doctorsApi from "../api/doctors";
class CreateProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			userName: "",
			phoneNumber: "",
			employmentType: "part-time",
			email: "",
			gender: "male",
			specialization: "",
			birthDate: "",
			bio: "",
			password: "",
			department: "",
			address: "",
			languages: [],
		};
	}

	render() {
		var loading = false;

		let handleSubmit = () => {
			loading = true;
			console.log("this is the new doctrs data", this.state);
			toast.promise(doctorsApi.add({ ...this.state }, onSuccess, onFaileure), {
				pending: "proccessing ...",
			});
		};

		const onSuccess = () => {
			loading = false;
		};
		const onFaileure = (error) => {
			console.log("This Failed : ", error);

			loading = false;
		};

		const handleChange = (event) => {
			this.setState({ [event.target.name]: event.target.value });
		};
		const setPhone = (phone) => {
			this.setState({ phoneNumber: phone });
		};

		const setDate = (number) => {
			this.setState({ phoneNumber: number });
		};

		const setLanguages = (languages) => {
			this.setState({ languages: languages });
		};

		const setLocation = (locations) => {
			this.setState({ address: locations });
		};
		return (
			<Container fluid className='main-content-container px-4'>
				<ToastContainer style={{ paddingTop: "50px" }} />

				<Row noGutters className='page-header py-4'>
					<Breadcrumb>
						<BreadcrumbItem>
							<Link to='/admin/doctors'>Doctors</Link>
						</BreadcrumbItem>
						<BreadcrumbItem active>
							<PageTitle
								title=''
								subtitle='Create Profile'
								md='12'
								className='ml-sm-auto mr-sm-auto'
							/>
						</BreadcrumbItem>
					</Breadcrumb>
				</Row>
				<Row>
					{/* <Col lg='4'>
						<UserProfile userDetails={this.props.user} setAvatar={setAvatar} />
					</Col> */}
					<Col lg='12'>
						<UserAccountForm
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							state={this.state}
							loadin={loading}
							setDate={setDate}
							phone={this.state.phone}
							setPhone={setPhone}
							setLocation={setLocation}
							setLanguages={setLanguages}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

export default connect(mapStateToProps)(CreateProfile);
