import { useSelector } from "react-redux";
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import PartTimeDoctorSchedule from "./PartTimeDoctorSchedule";
import RegularDoctorSchedule from "./RegularDoctorSchedule";

const DoctorSchedule = () => {
	const doctor = useSelector((state) => state.user[0]);

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title='Timing Duration'
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>

			{doctor && doctor.employmentType === "full-time" ? (
				<RegularDoctorSchedule />
			) : (
				<PartTimeDoctorSchedule />
			)}
		</Container>
	);
};

export default DoctorSchedule;
