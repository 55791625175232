import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ENDPOINT } from "../api/Api";
import Loading from "../components/loading/loading.page";

const Activate = () => {
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		const config = {
			headers: {
				Authorization: `${sessionStorage.getItem("token")}`,
			},
		};
		axios
			.post(
				`${ENDPOINT}/user/activate-account`,
				{
					activation_token: params.id,
				},
				config
			)
			.then((res) => {
				toast.success(res.data.message);
				setTimeout(() => navigate("/"), 2000);
			})
			.catch((error) => {
				if (error.response.data.message === "This email already exists.") {
					setTimeout(() => navigate("/"), 2000);
				} else {
					console.log("This Failed : ", error);
				}
			});

		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<ToastContainer />
			<Loading />
		</div>
	);
};

export default Activate;
