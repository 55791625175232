import { apiCall, apiFetch } from "./ApiCall";
const languageApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/language", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/language/", onSuccess, onFailure),
	getOne: (id, onSuccess, onFailure) =>
		apiFetch("get", `/language/${id}`, onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/language/${id}`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/language/${id}`, onSuccess, onFailure),
};

export default languageApi;
