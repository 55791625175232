import { apiCall } from "./ApiCall";
const specialDaysApi = {
	deleteOne: (data, id, onSuccess, onFailure) =>
		apiCall(
			"post",
			data,
			`/doctor/${id}/delete-working-time`,
			onSuccess,
			onFailure
		),
	updateSpecialDay: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/doctor/${id}/special-days/`, onSuccess, onFailure),
};

export default specialDaysApi;
