import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import appointmentApi from "../api/appointment";
import doctorsApi from "../api/doctors";
import PageTitle from "../components/common/PageTitle";
import Featching from "../components/featching/featching";
import { doctorsAdded } from "../store/doctors-reducer";
import { formatAppointmentAdmin } from "../utils/formatResponse";

const Appointments = () => {
	const columns = [
		{ title: "Patient", field: "patient" },
		{
			title: "Doctor",
			field: "doctor",
		},

		{
			title: "Date",
			field: "date",
		},
		{
			title: "Time",
			field: "time",
		},
		{
			title: "Reason",
			field: "reason",
			width: "80%",
		},
	];

	const navigate = useNavigate();

	const doctors = useSelector((state) => state.doctors);

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState("all");
	const dispatch = useDispatch();
	useEffect(() => {
		doctorsApi.getAll(onSuccess, onFaileure);
		setLoading(true);
		// eslint-disable-next-line
	}, [filter]);
	useEffect(() => {
		doctors && appointmentApi.getAll(apptFetchSuccess, onFaileure);
		// eslint-disable-next-line
	}, [doctors, filter]);

	const apptFetchSuccess = (res) => {
		const sorted = formatAppointmentAdmin(doctors, res.data.appointments).sort(
			function (a, b) {
				var dateA = new Date(a.date),
					dateB = new Date(b.date);
				return dateA - dateB;
			}
		);

		let filterd = [];
		filter === "today"
			? (filterd = sorted.filter(
					(paitent) =>
						new Date(paitent.date).getMonth() === new Date().getMonth() &&
						new Date(paitent.date).getDay() === new Date().getDay() &&
						new Date(paitent.date).getFullYear() === new Date().getFullYear()
			  ))
			: filter === "past"
			? (filterd = sorted.filter(
					(paitent) => new Date(paitent.date) < new Date()
			  ))
			: filter === "all"
			? (filterd = sorted)
			: (filterd = sorted.filter(
					(paitent) => new Date(paitent.date) > new Date()
			  ));

		setData(filterd);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const handleDelete = (id) => {
		appointmentApi.deleteOne(id, onDelete, onFaileure);
	};

	const onDelete = (res) => {
		doctorsApi.getAll(onSuccess, onFaileure);
		toast.success(res.message);
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (res) => {
		// setTempDocs(res.data);

		dispatch(doctorsAdded(res.data.filter((user) => user.role !== "admin")));
		// setDoctors(res.data);
	};

	const onEdit = (rowData) => {
		navigate(`/admin/edit-appointment/${rowData.docId}/${rowData._id}`);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Appointments' className='text-sm-left' />
			</Row>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{ display: "flex", justifyContent: "space-between" }}
						>
							<h6 className='m-0 text-white'>All Appointents</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 table__body'>
							<MaterialTable
								title={
									<div className='pt-4' style={{ display: "flex" }}>
										<h4 style={{ margin: "0px 10px" }}>Filter </h4>
										<Button
											size='sm'
											outline={filter !== "all"}
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("all")}
										>
											All
										</Button>
										<Button
											size='sm'
											outline={filter !== "past"}
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("past")}
										>
											Past
										</Button>
										<Button
											size='sm'
											outline={filter !== "today"}
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("today")}
										>
											Today
										</Button>
										<Button
											outline={filter !== "upcoming"}
											size='sm'
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("upcoming")}
										>
											Upcoming
										</Button>
									</div>
								}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								actions={[
									{
										icon: "edit",
										tooltip: "Edit Doctor",
										onClick: (event, rowData) => {
											onEdit(rowData);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Appointments;
