import React, { useEffect } from "react";
import { Nav } from "shards-react";

import UserActions from "./UserActions";

import { useDispatch, useSelector } from "react-redux";

import userApi from "../../../../api/user";
import { userSignedIn, userSignedOut } from "../../../../store/user-reducer";
import languageApi from "../../../../api/language";
import { languagesAdded } from "../../../../store/language-reducer";
import loactionApi from "../../../../api/location";
import { locationAdded } from "../../../../store/location-reducer";

const Func = () => {
	const user = useSelector((state) => state.user[0]);
	const dispatch = useDispatch();

	const onSuccess = (resp) => {
		dispatch(userSignedIn(resp.data.doctor));
	};

	const onFaliure = (error) => {
		console.log("this Failed", error);
	};

	useEffect(() => {
		languageApi.getAll(onLanguageSuccess, onFaliure);
		loactionApi.getAll(onLocationSuccess, onFaliure);
		userApi.getOne(sessionStorage.getItem("userId"), onSuccess, onFaliure);
		// eslint-disable-next-line
	}, []);

	const onLanguageSuccess = (resp) => {
		let temp = [];
		resp.data.languages.map((lang) =>
			temp.push({
				value: lang.title,
				label: lang.title.charAt(0).toUpperCase() + lang.title.slice(1),
				_id: lang._id,
			})
		);

		dispatch(languagesAdded(temp));
	};
	const onLocationSuccess = (resp) => {
		let temp = [];
		console.log("heniiii", resp);
		resp.data.addresses.map((location) =>
			temp.push({
				value: location.city,
				label: location.city.charAt(0).toUpperCase() + location.city.slice(1),
				_id: location._id,
			})
		);

		dispatch(locationAdded(temp));
	};

	const onLogout = () => {
		dispatch(userSignedOut([]));
		userApi.logout(
			(res) => {
				console.log("logged out", res);
				sessionStorage.clear();
			},
			(error) => {
				console.log("cant log out", error);
				sessionStorage.clear();
			}
		);
	};

	return (
		<Nav navbar className='border-left flex-row'>
			{/* <Notifications /> */}
			<UserActions onLogout={onLogout} user={user} />
		</Nav>
	);
};
export default Func;
