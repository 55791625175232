import { apiCall, apiFetch } from "./ApiCall";
const doctorsApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/doctor", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/doctor/all", onSuccess, onFailure),
	getOne: (id, onSuccess, onFailure) =>
		apiFetch("get", `/doctor/${id}`, onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/doctor/${id}`, onSuccess, onFailure),
	updateDoctor: (id, data, onSuccess, onFailure) =>
		apiCall("put", data, `/doctor/${id}`, onSuccess, onFailure),
	getAppointment: (id, onSuccess, onFailure) =>
		apiFetch("get", `/appointment/get-by-doctor/${id}`, onSuccess, onFailure),
	activate: (id, onSuccess, onFailure) =>
		apiCall("put", {}, `/doctor/${id}/activate`, onSuccess, onFailure),
	deactivate: (id, onSuccess, onFailure) =>
		apiCall("put", {}, `/doctor/${id}/deactivate`, onSuccess, onFailure),
};

export default doctorsApi;
