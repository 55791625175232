import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import departmentsApi from "../../api/departments";

const CreateDepartment = ({ fetch, setFetch }) => {
	const [deptName, setDeptName] = useState("");
	const [loading, setLoading] = useState(false);
	const handleSubmit = () => {
		setLoading(false);
		toast.promise(
			departmentsApi.add({ deptName: deptName }, onSuccess, onFaileure),
			{
				pending: "proccessing ...",
			}
		);
	};

	const onSuccess = () => {
		setLoading(false);
		setFetch(!fetch);
		setDeptName("");
	};
	const onFaileure = (error) => {
		console.log(error);
		setLoading(false);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>Create Department</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row style={{ padding: "30px 0" }}>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									<Col md='4' className='form-group'>
										<FormInput
											md='6'
											requ
											id='feDepartment'
											placeholder='Department Name'
											name='deptname'
											value={deptName}
											onChange={(e) => setDeptName(e.target.value)}
											required
										/>
									</Col>
									<Col md='4' className='form-group'>
										<Button theme='accent' disabled={loading} type='submit'>
											Create Department
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

CreateDepartment.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

CreateDepartment.defaultProps = {
	title: "Account Details",
};

export default CreateDepartment;
