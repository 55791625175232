import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Modal,
	Row,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { formatAMPM } from "../utils/formatAMPM";
import { toast, ToastContainer } from "react-toastify";
import specialDaysApi from "../api/specialDays";
import TimeSelector from "../components/TimeSelector/TimeSelector.component";
import doctorsApi from "../api/doctors";
import { userSignedIn } from "../store/user-reducer";
import { formatDate } from "../utils/formatDate";

const SpecialDays = () => {
	const [filter, setFilter] = useState(0);
	const [open, setOpen] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [fetch, setFetch] = useState(false);
	const [date, setDate] = useState(new Date());

	const doctor = useSelector((state) => state.user[0]);

	const dispatch = useDispatch();

	useEffect(() => {
		doctor && doctor.workingDaysSpecial
			? setShifts(
					doctor.workingDaysSpecial.filter(
						(days) => new Date() <= new Date(days.day)
					)
			  )
			: setShifts([]);
	}, [doctor, fetch, filter]);

	useEffect(() => {
		doctorsApi.getOne(
			sessionStorage.getItem("userId"),
			doctorFetchSuccess,
			onFaileure
		);
		// eslint-disable-next-line
	}, [fetch]);

	const doctorFetchSuccess = (user) => {
		dispatch(userSignedIn(user.data.doctor));
	};

	const toggle = () => {
		setOpen(!open);
	};
	const handleDelete = (id) => {
		specialDaysApi.deleteOne(
			{
				workingTimeId: id,
				type: "special",
			},
			doctor._id,
			deleteSuccess,
			onFaileure
		);
	};

	const handleSave = (newSlot) => {
		toast.promise(
			specialDaysApi.updateSpecialDay(
				{
					day: date,
					availableHours: [{ ...newSlot }],
				},
				doctor._id,
				fetchSuccess,
				onFaileure
			),
			{
				pending: "Saving ...",
			}
		);
	};
	const onFaileure = (error) => {
		console.log("This Failed : ", error);

		setFetch(!fetch);
	};
	const fetchSuccess = () => {
		setFetch(!fetch);
	};
	const deleteSuccess = (res) => {
		toast.success(res.message);
		setFetch(!fetch);
	};

	const changeTab = (e) => {
		setFilter(e.target.value);
	};
	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title='Edit Special Day'
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>
			<TimeSelector
				open={open}
				toggle={toggle}
				handleChange={handleSave}
				date={date}
				setDate={setDate}
			/>

			<Row>
				{/* Editor */}
				<Col lg='12' md='12'>
					<Card small className='mb-3 mt-4 '>
						<Row
							style={{
								padding: "30px 50px",
								gridGap: "20px",
								height: "85px",
								marginBottom: "20px",
								paddingLeft: "35px",
							}}
							className='desktop'
						>
							<Col md='4' sm='4' className='form-group mt-3'>
								<Button theme='success' className='save ' onClick={toggle}>
									Add New Date
								</Button>
							</Col>
						</Row>
						{shifts[0] && <hr />}
						{shifts[0] && (
							<Row
								style={{
									padding: "30px 50px",
									display: "grid",
									gridTemplateColumns: `repeat(${shifts.length},1fr)`,
									gridGap: "20px",
									height: "85px",
									paddingBottom: "0",
								}}
								className='desktop'
							>
								{shifts[0] &&
									shifts.map((day, index) => (
										<Button
											theme='primary'
											className='mb-2 mr-1  w100 hover-red'
											outline={index === parseInt(filter) ? false : true}
											value={index}
											onClick={changeTab}
											name={day}
											key={index}
										>
											{formatDate(new Date(day.day))}
										</Button>
									))}
							</Row>
						)}

						<Row className='mobile' style={{ padding: "0 30px" }}>
							<Row lg='8'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect id='Days' onChange={changeTab}>
										{shifts[0] ? (
											shifts.map((day, index) => (
												<option
													value={index}
													onClick={changeTab}
													name={day}
													key={index}
												>
													{formatDate(new Date(day.day))}
												</option>
											))
										) : (
											<option>No Special Dates</option>
										)}
									</FormSelect>
								</Col>
							</Row>
						</Row>
						<Col md='4' sm='4' className='form-group  mobile'>
							<Button theme='success' onClick={toggle}>
								Add New Date
							</Button>
						</Col>
						<hr />
						<Row
							style={{
								padding: "10px 30px",
							}}
						>
							<Modal show />
							{shifts.length ? (
								<Col>
									<h4>Time Slots</h4>
								</Col>
							) : (
								<Col>
									<h4>Not Time Slots</h4>
								</Col>
							)}
						</Row>
						<Row className='time-slot-container'>
							{shifts[0] &&
								shifts[filter] &&
								shifts[filter].availableHours.map((schedule, index) => (
									<Button
										theme='primary'
										key={index}
										value={schedule._id}
										className='mb-2 mr-1 time-slots'
										onClick={() => handleDelete(shifts[filter]._id)}
									>
										{`${formatAMPM(
											schedule.startHour.hour,
											schedule.startHour.minute
										)}
										-
										${formatAMPM(schedule.endHour.hour, schedule.endHour.minute)}`}
										<span value={schedule._id} className='close'>
											X
										</span>
									</Button>
								))}
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default SpecialDays;
