import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

import { Store } from "../../../flux";

class MainSidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			menuVisible: false,
			sidebarNavItems:
				sessionStorage.getItem("role") === "admin"
					? Store.getAdminNavItems()
					: Store.getDoctorNavItems(),
		};

		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		Store.addChangeListener(this.onChange);
	}

	componentWillUnmount() {
		Store.removeChangeListener(this.onChange);
	}

	onChange() {
		this.setState({
			...this.state,
			menuVisible: Store.getMenuState(),
			sidebarNavItems:
				sessionStorage.getItem("role") === "Doctor"
					? Store.getDoctorNavItems()
					: Store.getAdminNavItems(),
		});
	}

	render() {
		const classes = classNames(
			"main-sidebar",
			"px-0",
			"col-12",
			this.state.menuVisible && "open"
		);

		return (
			<Col tag='aside' className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
				<SidebarMainNavbar
					hideLogoText={this.props.hideLogoText}
					role={`${sessionStorage.getItem("role")}s Dashoard`}
				/>

				<SidebarNavItems user={this.props.user} />
			</Col>
		);
	}
}

MainSidebar.propTypes = {
	/**
	 * Whether to hide the logo text, or not.
	 */
	hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
	hideLogoText: false,
};

export default MainSidebar;
