import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	BreadcrumbItem,
	Card,
	CardHeader,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import doctorsApi from "../api/doctors";
import languageApi from "../api/language";
import loactionApi from "../api/location";
import userApi from "../api/user";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";
import { languagesAdded } from "../store/language-reducer";
import { locationAdded } from "../store/location-reducer";
import { removeKey } from "../utils/deleteKeys";
const EditDoctorProfile = () => {
	const params = useParams();
	const doctor = useSelector((state) =>
		state.doctors.find((doc) => doc._id === params.id)
	);
	const [tempUser, setTempUser] = useState(doctor, params);
	const [fetch, setFetch] = useState(false);
	const dispatch = useDispatch();

	const [avatar, setAvatar] = useState(null);

	const handleSubmit = () => {
		toast.promise(
			doctorsApi.updateDoctor(
				params.id,
				removeKey(tempUser),
				onSuccess,
				onFaileure
			),
			{
				pending: "proccessing ...",
			}
		);
	};

	useEffect(() => {
		doctorsApi.getOne(params.id, onFetchSuccess, onFaileure);
		// eslint-disable-next-line
	}, [fetch]);
	useEffect(() => {
		languageApi.getAll(onLanguageSuccess, onFaileure);
		loactionApi.getAll(onLocationSuccess, onFaileure);
		// eslint-disable-next-line
	}, []);

	const onLanguageSuccess = (resp) => {
		let temp = [];
		resp.data.languages.map((lang) =>
			temp.push({
				value: lang.title,
				label: lang.title.charAt(0).toUpperCase() + lang.title.slice(1),
				_id: lang._id,
			})
		);

		dispatch(languagesAdded(temp));
	};
	const onLocationSuccess = (resp) => {
		let temp = [];

		resp.data.addresses.map((location) =>
			temp.push({
				value: location.city,
				label: location.city.charAt(0).toUpperCase() + location.city.slice(1),
				_id: location._id,
			})
		);

		dispatch(locationAdded(temp));
	};

	const onSuccess = () => {
		console.log("profile update success");
		setFetch(!fetch);
	};
	const onFetchSuccess = (response) => {
		setTempUser(response.data.doctor);
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const changeAvatar = (e) => {
		const formData = new FormData();
		// HTML file input, chosen by user
		formData.append("image", e);
		userApi.updateAvatar(
			params.id,
			formData,
			() => {
				setAvatar(URL.createObjectURL(e));
				setFetch(!fetch);
			},
			onFailure
		);
	};

	const onFailure = (error) => {
		console.log(error);
	};
	return (
		<Container fluid className='main-content-container px-4'>
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					{tempUser && tempUser.role === "admin" ? (
						<BreadcrumbItem>
							<Link to='/admin/admins'>Adminstrators</Link>
						</BreadcrumbItem>
					) : (
						<BreadcrumbItem>
							<Link to='/admin/doctors'>Doctor</Link>
						</BreadcrumbItem>
					)}
					{tempUser && tempUser.role === "admin" ? (
						<BreadcrumbItem active>
							<PageTitle
								title=''
								subtitle='Edit Admin Profile'
								md='12'
								className='ml-sm-auto mr-sm-auto'
							/>
						</BreadcrumbItem>
					) : (
						<BreadcrumbItem active>
							<PageTitle
								title=''
								subtitle='Edit Docotr Profile'
								md='12'
								className='ml-sm-auto mr-sm-auto'
							/>
						</BreadcrumbItem>
					)}
				</Breadcrumb>
			</Row>
			<ToastContainer />
			<Row>
				<Col lg='4'>
					{tempUser && (
						<Card small className='mb-4 pt-3'>
							<CardHeader className='border-bottom text-center'>
								<div className='mb-3 mx-auto'>
									<img
										className='rounded-circle'
										src={avatar ? avatar : tempUser.profilePicture}
										alt={tempUser.name}
										width='110'
									/>
								</div>
								<h4 className='mb-0'>{tempUser.name}</h4>
								<span className='text-muted d-block mb-2'>
									{tempUser.jobTitle}
								</span>
							</CardHeader>
							<ListGroup flush>
								<ListGroupItem className='p-4'>
									<strong className='text-muted d-block mb-2'>
										Specialization: {tempUser.specialization}
									</strong>
									<CustomFileUpload onChange={changeAvatar} />
								</ListGroupItem>
							</ListGroup>
						</Card>
					)}
				</Col>
				<Col lg='8'>
					{tempUser && (
						<UserAccountDetails
							user={tempUser}
							setUser={setTempUser}
							handleSubmit={handleSubmit}
						/>
					)}
				</Col>
			</Row>
		</Container>
	);
};
export default EditDoctorProfile;
