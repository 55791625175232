import { apiCall, apiFetch } from "../api/ApiCall";
const userApi = {
	getOne: (id, onSuccess, onFailure) => {
		apiFetch("get", `/doctor/${id}`, onSuccess, onFailure);
	},
	getAll: (onSuccess, onFailure) => {
		apiFetch("get", `/user/`, onSuccess, onFailure);
	},
	login: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/user/login", onSuccess, onFailure);
	},
	getRefreshToken: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/user/refresh-token", onSuccess, onFailure);
	},
	logout: (onSuccess, onFailure) => {
		apiFetch("get", "/user/logout", onSuccess, onFailure);
	},
	activate: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/user/activate-account", onSuccess, onFailure);
	},
	changePassword: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/user/change-password", onSuccess, onFailure);
	},
	forgetPassword: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/user/forgot-password", onSuccess, onFailure);
	},
	resetPassword: (data, onSuccess, onFailure, id) => {
		apiCall("post", data, "/user/reset-password", onSuccess, onFailure, id);
	},
	update: (data, onSuccess, onFailure) => {
		apiCall("put", data, "/user/", onSuccess, onFailure);
	},
	deleteOne: (id, onSuccess, onFailure) => {
		apiFetch("delete", `/doctor/${id}`, onSuccess, onFailure);
	},
	register: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/user/register", onSuccess, onFailure);
	},
	updateAvatar: (id, data, onSuccess, onFailure) => {
		apiCall("patch", data, `/user/update-avatar/${id}`, onSuccess, onFailure);
	},
};

export default userApi;
