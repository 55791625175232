import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Row,
} from "shards-react";
import appointmentApi from "../../api/appointment";
import PageTitle from "../../components/common/PageTitle";
import { formatAMPM } from "../../utils/formatAMPM";
import { formatDate } from "../../utils/formatDate";
import Featching from "../featching/featching";
import "./AppoiintmentEditor.styles.css";
const AppoiintmentEditor = () => {
	const [days, setDays] = useState([]);
	const [date, setDate] = useState(
		days && formatDate(new Date())
		// getting the next day
	);
	const navigate = useNavigate();
	const params = useParams();
	const [time, setTime] = useState([]);
	const [apptTime, setApptTime] = useState(time && time[0]);

	const [fetch, setFetch] = useState();
	const [featching, setFetching] = useState(false);

	const convertor = (date) => {
		var today = new Date(date);
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + "-" + mm + "-" + dd;
		return today;
	};
	const renderDayContents = (day, date) => {
		const tooltipText = `Tooltip for date: ${date}`;
		return (
			<div>
				{days.includes(convertor(date)) ? (
					<span title={tooltipText}>{new Date(date).getDate()}</span>
				) : (
					<span className='inactive' title={tooltipText}>
						{new Date(date).getDate()}
					</span>
				)}
			</div>
		);
	};

	useEffect(() => {
		appointmentApi.getTimeByDoctor(
			params.doctor,
			formatDate(new Date(date)),
			fetchWorkingTimeSuccess,
			onFaileure
		);
		// eslint-disable-next-line
	}, [date, fetch]);

	useEffect(() => {
		setFetching(true);
		appointmentApi.getDateByDoctor(
			params.doctor,
			fetchWorkingDateSuccess,
			(error) => console.log("error", error)
		);
		// eslint-disable-next-line
	}, [fetch]);

	const fetchWorkingDateSuccess = (resp) => {
		setDays(resp.data.availableDates);
		setDate(new Date(resp.data.availableDates[0]));
	};
	const fetchWorkingTimeSuccess = (resp) => {
		setFetching(false);
		setTime(resp.data.available_hours);
	};

	const updateAppointment = (e) => {
		e.preventDefault();

		if (!apptTime) {
			return toast.error("Please select time");
		}

		let data = {
			appointmentDate: date,
			appointmentTime: {
				hour: apptTime.hour,
				minute: apptTime.minute,
			},
		};
		toast.promise(
			appointmentApi.updateAppointment(
				data,
				params.appointment,
				onSuccess,
				onFaileure
			),
			{
				pending: "Making Appointment ...",
			}
		);
	};

	const onFaileure = (error) => {
		setTime([]);
	};

	const onSuccess = (data) => {
		setFetch(!fetch);
		setTimeout(() => {
			navigate(-1);
		}, 3000);
		//
	};

	return (
		<Container fluid className='main-content-container px-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link
							to='#'
							onClick={() => {
								navigate(-1);
							}}
						>
							Appointments
						</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Appointment'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>
			{!featching ? (
				<Col lg='6'>
					<Card small className='mb-4 p-4'>
						<form onSubmit={updateAppointment} method='post'>
							<FormSelect
								required
								name='time'
								onChange={(e) => setApptTime(time[e.target.value])}
							>
								<option selected disabled>
									Select time
								</option>
								{time &&
									time.map((schedule, index) => (
										<option key={index} value={index}>
											{`${formatAMPM(schedule.hour, schedule.minute)}
										`}
										</option>
									))}
							</FormSelect>

							<DatePicker
								className='form-control'
								selected={new Date(date)}
								onChange={(date) => setDate(formatDate(date))}
								renderDayContents={renderDayContents}
							/>
							<Button
								theme='accent'
								type='submit'
								style={{ marginTop: "20px" }}
							>
								Update Appointment
							</Button>
						</form>
					</Card>
				</Col>
			) : (
				<Col lg='12' style={{ marginTop: "20vh" }}>
					<Featching />
				</Col>
			)}
		</Container>
	);
};

export default AppoiintmentEditor;
