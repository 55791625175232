import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import doctorsApi from "../api/doctors";
import { formatAppointments } from "../utils/formatResponse";
import Featching from "../components/featching/featching";
import appointmentApi from "../api/appointment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DocAppointments = () => {
	const columns = [
		{ title: "Name", field: "name" },

		{
			title: "Reason For Visit",
			field: "reasonForVisit",
		},
		{
			title: "Gender",
			field: "gender",
		},
		{
			title: "Appointment Date",
			field: "date",
		},
		{
			title: "Appointment Time",
			field: "time",
		},
	];
	const [loading, setLoading] = useState(true);

	const [filter, setFilter] = useState("all");
	const user = useSelector((state) => state.user[0]);
	const [appointments, setAppointments] = useState([]);
	const navigate = useNavigate();
	useEffect(() => {
		setLoading(true);
		doctorsApi.getAppointment(
			sessionStorage.getItem("userId"),
			onSuccess,
			onFaileure
		);
		// eslint-disable-next-line
	}, [user, filter]);

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const handleDelete = (id) => {
		appointmentApi.deleteOne(id, onDelete, onFaileure);
	};

	const onDelete = (res) => {
		doctorsApi.getAppointment(
			sessionStorage.getItem("userId"),
			onSuccess,
			onFaileure
		);
	};
	const onSuccess = (res) => {
		setLoading(false);
		let filterd = [];
		const sorted = res.data.appointments.sort(function (a, b) {
			var dateA = new Date(a.appointmentDate),
				dateB = new Date(b.appointmentDate);
			return dateA - dateB;
		});

		console.log("this is hena", res.data.appointments);

		filter === "today"
			? (filterd = sorted.filter(
					(paitent) =>
						new Date(paitent.appointmentDate).getMonth() ===
							new Date().getMonth() &&
						new Date(paitent.appointmentDate).getDay() ===
							new Date().getDay() &&
						new Date(paitent.appointmentDate).getFullYear() ===
							new Date().getFullYear()
			  ))
			: filter === "past"
			? (filterd = sorted.filter(
					(paitent) => new Date(paitent.appointmentDate) < new Date()
			  ))
			: filter === "all"
			? (filterd = sorted)
			: (filterd = sorted.filter(
					(paitent) => new Date(paitent.appointmentDate) > new Date()
			  ));
		setAppointments(formatAppointments(filterd));
	};
	const onEdit = (rowData) => {
		navigate(`/admin/edit-appointment/${rowData.docId}/${rowData._id}`);
	};
	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title='Appointments'
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>
			<Row>
				<Col>
					<Card small className='mb-4'>
						<CardBody className='p-0'>
							<MaterialTable
								title={
									<div className='pt-4' style={{ display: "flex" }}>
										<h4 style={{ margin: "0px 10px" }}>Filter </h4>
										<Button
											size='sm'
											outline={filter !== "all"}
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("all")}
										>
											All
										</Button>
										<Button
											size='sm'
											outline={filter !== "past"}
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("past")}
										>
											Past
										</Button>

										<Button
											size='sm'
											outline={filter !== "today"}
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("today")}
										>
											Today
										</Button>
										<Button
											outline={filter !== "upcoming"}
											size='sm'
											theme='primary'
											className='mb-2 mr-1'
											onClick={() => setFilter("upcoming")}
										>
											Upcoming
										</Button>
									</div>
								}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								columns={columns}
								data={appointments}
								actions={[
									{
										icon: "edit",
										tooltip: "Edit Doctor",
										onClick: (event, rowData) => {
											onEdit(rowData);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default DocAppointments;
