import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	CardHeader,
	Col,
	Container,
	FormSelect,
	ListGroupItem,
	Row,
} from "shards-react";
import appointmentApi from "../api/appointment";
import PageTitle from "../components/common/PageTitle";

const EditTimeRange = () => {
	const [timeRange, setTimeRange] = useState(30);
	const [fetch, setFetch] = useState(false);
	let list = [10, 20, 30, 40, 50, 60];

	useEffect(() => {
		appointmentApi.getRange(
			(res) => {
				setTimeRange(res.appointTime[0].duration);
			},
			(error) => {
				console.log("This Failed : ", error);
			}
		);
	}, [fetch]);

	const handleUpdate = () => {
		toast.promise(
			appointmentApi.updateRange(
				{
					type: "follow-up",
					duration: timeRange,
				},
				onSuccess,
				onFaileure
			),
			{
				pending: "Saving ...",
			}
		);
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);

		setFetch(!fetch);
	};

	const onSuccess = (data) => {
		setFetch(!fetch);
	};

	return (
		<Container fluid className='main-content-container px-4 '>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Departments' className='text-sm-left' />
			</Row>

			<ToastContainer />

			<CardHeader className='border-bottom'>
				<h6 className='m-0'>Update Working Time Range</h6>
			</CardHeader>

			<ListGroupItem className='p-3'>
				<Row style={{ padding: "30px 0" }}>
					<Col>
						<Row form>
							<Col md='4' className='form-group'>
								<FormSelect
									size='sm'
									value={timeRange}
									onChange={(e) => {
										setTimeRange(parseInt(e.target.value));
									}}
								>
									{list.map((li, index) => (
										<option key={index}>{li}</option>
									))}
								</FormSelect>
							</Col>
							<Col md='4' className='form-group'>
								<Button theme='accent' onClick={handleUpdate}>
									Update
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</ListGroupItem>
		</Container>
	);
};

export default EditTimeRange;
