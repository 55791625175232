import React, { useState } from "react";

const CustomFileUpload = ({ onChange }) => {
	return (
		<div className='custom-file mb-3'>
			<input
				type='file'
				className='custom-file-input'
				id='customFile2'
				onChange={(e) => {
					onChange(e.target.files[0]);
				}}
			/>
			<label className='custom-file-label' htmlFor='customFile2'>
				Choose file...
			</label>
		</div>
	);
};

export default CustomFileUpload;
