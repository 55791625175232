import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";

import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	FormSelect,
	FormTextarea,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import departmentsApi from "../../api/departments";
import { departmentsAdded } from "../../store/departments-reducer";
import { formatDepartment } from "../../utils/formatResponse";

const UserAccountForm = ({
	title,
	handleChange,
	handleSubmit,
	setPhone,
	phone,
	loading,
	setLocation,
	setLanguages,
}) => {
	const [departments, setDepartments] = useState([]);

	const dispatch = useDispatch();

	const languages = useSelector((state) => state.language);
	const locations = useSelector((state) => state.location);

	useEffect(() => {
		departmentsApi.getActive(fetchSuccess, onFaliure);
		// eslint-disable-next-line
	}, [fetch]);

	const onFaliure = (error) => {
		console.log(error.message);
	};
	const fetchSuccess = (res) => {
		dispatch(departmentsAdded(res.data.departments));
		setDepartments(formatDepartment(res.data.departments));
	};

	const languageChange = (newValue, actionMeta) => {
		let temp = [];

		newValue.map((data) => {
			if (data.__isNew__) {
				return temp.push({ title: data.value.toLowerCase() });
			} else return temp.push(data._id);
		});

		setLanguages(temp);
	};

	const locationChange = (newValue, actionMeta) => {
		if (newValue.__isNew__) {
			setLocation({ city: newValue.value.toLowerCase() });
		} else setLocation(newValue._id);
	};
	useEffect(() => {
		departmentsApi.getActive(
			(res) => {
				setDepartments(res.data.departments);
				handleChange({
					target: { name: "department", value: res.data.departments[0]._id },
				});
			},
			(error) => {
				console.log("This Failed : ", error);
			}
		);
		// eslint-disable-next-line
	}, []);
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feFirstName'>First Name</label>
										<FormInput
											id='feFirstName'
											placeholder='First Name'
											name='firstName'
											onChange={handleChange}
											required
										/>
									</Col>
									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feLastName'>Last Name</label>
										<FormInput
											id='feLastName'
											placeholder='Last Name'
											name='lastName'
											onChange={handleChange}
											required
										/>
									</Col>

									{/* Email */}
									<Col md='6' className='form-group'>
										<label htmlFor='feEmail'>Email</label>
										<FormInput
											type='email'
											id='feEmail'
											placeholder='Email Address'
											name='email'
											onChange={handleChange}
											required
											autoComplete=''
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feUsername'>Username</label>
										<FormInput
											type='text'
											id='feUsername'
											placeholder='Username'
											name='userName'
											onChange={handleChange}
											required
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='fePhone'>Phone Number</label>
										<PhoneInput
											className='form-control '
											placeholder='Enter phone number'
											value={phone}
											onChange={(data) => setPhone(data)}
											required
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feEmploymentType'>Employment Type</label>

										<FormSelect
											id='feEmploymentType'
											required
											name='employmentType'
											onChange={handleChange}
										>
											<option selected='true' value='' disabled='disabled'>
												Employment Type
											</option>
											<option value='part-time'>part-time</option>

											<option value='full-time'>full-time</option>
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feEmploymentType'>Department</label>
										<FormSelect
											id='feDepartment'
											name='department'
											required
											onChange={handleChange}
										>
											<option selected='true' value='' disabled='disabled'>
												Department
											</option>
											{departments.map((dept, idx) => (
												<option key={idx} value={dept._id}>
													{dept.deptName}
												</option>
											))}
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feGender'>Gender</label>
										<FormSelect
											id='feGender'
											name='gender'
											onChange={handleChange}
											required
										>
											<option selected='true' value='' disabled='disabled'>
												Gender
											</option>
											<option value='male'>Male</option>

											<option value='female'>Female</option>
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSpecialization'>Specialization</label>
										<FormInput
											type='text'
											id='feSpecialization'
											placeholder='specialization'
											name='specialization'
											onChange={handleChange}
											required
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feBirthDate'>Birth Date</label>
										<FormInput
											type='date'
											id='feBirthDate'
											placeholder='specialization'
											name='birthDate'
											onChange={handleChange}
											required
										/>
									</Col>
									{/* Password */}
									<Col md='6' className='form-group'>
										<label htmlFor='fePassword'>Password</label>
										<FormInput
											type='password'
											id='fePassword'
											placeholder='Password'
											name='password'
											onChange={handleChange}
											required
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feAge'>City</label>
										<CreatableSelect
											isClearable
											onChange={locationChange}
											options={locations}
											defaultValue={[]}
											required
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feAge'>Languages</label>
										<CreatableSelect
											isClearable
											isMulti
											onChange={languageChange}
											options={languages}
											defaultValue={[]}
											required={true}
										/>
									</Col>
									<Col md='12' className='form-group'>
										<label htmlFor='feTextArea'>Bio</label>
										<FormTextarea
											type='textarea'
											id='feTextArea'
											placeholder='bio'
											name='bio'
											onChange={handleChange}
											hasValue={true}
										/>
									</Col>
								</Row>

								<Button theme='accent' disabled={loading} type='submit'>
									Create Account
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

UserAccountForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

UserAccountForm.defaultProps = {
	title: "Account Details",
};

export default UserAccountForm;
