import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Modal,
	Row,
} from "shards-react";
import closedDaysApi from "../api/closedDays";
import doctorsApi from "../api/doctors";
import PageTitle from "../components/common/PageTitle";
import TimeSelector from "../components/TimeSelector/TimeSelector.component";
import { doctorsAdded } from "../store/doctors-reducer";
import { formatAMPM } from "../utils/formatAMPM";
import { formatDate } from "../utils/formatDate";

const EditClosedDays = () => {
	const [filter, setFilter] = useState(0);
	const [open, setOpen] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [doctor, setDoctor] = useState(0);
	const [fetch, setFetch] = useState(false);
	const [date, setDate] = useState(new Date());

	const doctors = useSelector((state) => state.doctors);
	const dispatch = useDispatch();

	useEffect(() => {
		doctorsApi.getAll(fetchSuccess, fetchFaileure);
		// eslint-disable-next-line
	}, [doctor, fetch, filter]);

	const toggle = () => {
		setOpen(!open);
	};
	const handleDelete = (id) => {
		closedDaysApi.deleteOne(
			doctors[doctor]._id,
			{
				workingTimeId: id,
				type: "closed",
			},
			deleteSuccess,
			deleteFaileure
		);
	};
	const handleSave = (newSlot) => {
		toast.promise(
			closedDaysApi.updateClosedDay(
				{
					day: date,
					closedHours: [{ ...newSlot }],
				},
				doctors[doctor]._id,
				saveSuccess,
				saveFaileure
			),
			{
				pending: "Saving ...",
			}
		);
	};

	const saveFaileure = (error) => {
		console.log("This Failed : ", error);

		setFetch(!fetch);
	};
	const saveSuccess = (data) => {
		setFetch(!fetch);
	};
	const deleteFaileure = (error) => {
		console.log("This Failed : ", error);

		setFetch(!fetch);
	};
	const deleteSuccess = (resp) => {
		setFetch(!fetch);
	};
	const fetchFaileure = (error) => {
		console.log("This Failed : ", error);
	};
	const fetchSuccess = (res) => {
		dispatch(doctorsAdded(res.data.filter((user) => user.role !== "admin")));
		setShifts(
			res.data.filter((user) => user.role !== "admin")[doctor].closedDays
		);
	};

	const changeTab = (e) => {
		setFilter(e.target.value);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title='Edit Closed Day'
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>
			<TimeSelector
				open={open}
				toggle={toggle}
				handleChange={handleSave}
				date={date}
				setDate={setDate}
				fetch={fetch}
				setFetch={setFetch}
			/>
			<Row>
				{/* Editor */}
				<Col lg='12' md='12'>
					<Card small className='mb-3 mt-4 '>
						<Row
							style={{
								padding: "30px 50px",
								gridGap: "20px",
								height: "85px",
								marginBottom: "20px",
								paddingLeft: "35px",
							}}
							className='desktop'
						>
							<Col md='4' sm='4' className='form-group'>
								<label htmlFor='feBirthDate'>Select Doctor</label>
								<FormSelect
									id='Doctors'
									onChange={(e) => setDoctor(e.target.value)}
								>
									{doctors.map((doc, index) => (
										<option key={index} value={index}>
											Dr. {doc.firstName} {doc.lastName}
										</option>
									))}
								</FormSelect>
							</Col>
							<Col md='4' sm='4' className='form-group mt-3'>
								<Button theme='success' className='save ' onClick={toggle}>
									Add New Date
								</Button>
							</Col>
						</Row>

						<Row
							className='mobile'
							style={{ padding: "0 30px", paddingTop: "20px" }}
						>
							<Row lg='8'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect
										id='Doctors'
										onChange={(e) => setDoctor(e.target.value)}
									>
										{doctors.map((doc, index) => (
											<option key={index} value={index}>
												Dr. {doc.firstName} {doc.lastName}
											</option>
										))}
									</FormSelect>
								</Col>
							</Row>
						</Row>

						{shifts[0] && (
							<Row
								style={{
									padding: "30px 50px",
									display: "grid",
									gridTemplateColumns: `repeat(${shifts.length},1fr)`,
									gridGap: "20px",
									height: "85px",
									paddingBottom: "0",
								}}
								className='desktop'
							>
								{shifts[0] &&
									shifts.map((day, index) => (
										<Button
											theme='primary'
											className='mb-2 mr-1  w100 hover-red'
											outline={index === parseInt(filter) ? false : true}
											value={index}
											onClick={changeTab}
											name={day}
											key={index}
										>
											{formatDate(new Date(day.day))}
										</Button>
									))}
							</Row>
						)}

						<Row className='mobile' style={{ padding: "0 30px" }}>
							<Row lg='8'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect id='Days' onChange={changeTab}>
										{shifts[0] ? (
											shifts.map((day, index) => (
												<option
													value={index}
													onClick={changeTab}
													name={day}
													key={index}
												>
													{formatDate(new Date(day.day))}
												</option>
											))
										) : (
											<option>No Closed Date</option>
										)}
									</FormSelect>
								</Col>
							</Row>
						</Row>
						<Col md='4' sm='4' className='form-group  mobile'>
							<Button theme='success' onClick={toggle}>
								Add New Date
							</Button>
						</Col>
						<hr />
						<Row
							style={{
								padding: "10px 30px",
							}}
						>
							<Modal show />
							{shifts.length ? (
								<Col>
									<h4>Time Slots</h4>
								</Col>
							) : (
								<Col>
									<h4>Not Time Slots</h4>
								</Col>
							)}
						</Row>
						<Row className='time-slot-container'>
							{shifts[filter] &&
								shifts[filter].closedHours.map((schedule, index) => (
									<Button
										theme='primary'
										key={index}
										value={schedule._id}
										className='mb-2 mr-1 time-slots'
										onClick={() => handleDelete(shifts[filter]._id)}
									>
										{`${formatAMPM(
											schedule.startHour.hour,
											schedule.startHour.minute
										)}
										-
										${formatAMPM(schedule.endHour.hour, schedule.endHour.minute)}`}
										<span value={schedule._id} className='close'>
											X
										</span>
									</Button>
								))}
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EditClosedDays;
