import { useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Col,
	Form,
	FormInput,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "shards-react";
import departmentsApi from "../../api/departments";
import "./EditDepartment.styles.css";
const EditDepartment = ({ toggle, open, id, name, fetch, setFetch }) => {
	const [deptName, setDeptName] = useState(name);

	const handleUpdate = () => {
		toast.promise(
			departmentsApi.updateOne(
				{
					deptName: deptName,
				},
				id,
				onSuccess,
				onFaileure
			),
			{
				pending: "Saving ...",
			}
		);
		toggle();
	};
	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (data) => {
		setFetch(!fetch);
	};
	return (
		<Modal open={open} toggle={toggle} centered={true} size='md'>
			<ModalHeader>Edit {name} Department</ModalHeader>
			<Form onSubmit={handleUpdate} method='post'>
				<ModalBody>
					<Col md='12' className='form-group mb-4'>
						<FormInput
							md='6'
							id='feDepartment'
							placeholder='Department Name'
							name='deptname'
							value={deptName}
							onChange={(e) => setDeptName(e.target.value)}
							required
						/>
					</Col>
				</ModalBody>
				<ModalFooter>
					<Button
						theme='success'
						style={{ zIndex: "99999999999" }}
						type='submit'
					>
						Update
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default EditDepartment;
